<template>
  <div class="form-group">
    <label>{{ $t("form.selectCategory") }}</label>
    <select
      v-model="store.selectedCategory"
      :class="{ 'input-error': errors.selectedCategory }"
    >
      <option disabled value="">
        {{ $t("form.selectCategoryPlaceholder") }}
      </option>
      <optgroup
        v-for="category in store.categories"
        :key="category.id"
        :label="category.name"
      >
        <option
          v-for="subCategory in category.subCategories"
          :key="subCategory.id"
          :value="subCategory.id"
        >
          {{ subCategory.name }}
        </option>
      </optgroup>
    </select>
    <span v-if="errors.selectedCategory" class="error-text">
      {{ $t("form.categoryRequired") }}
    </span>
  </div>
</template>

<script>
import { useDataStore } from "@/stores/dataStore";
import categoriesMixin from "@/mixins/categoriesMixin";

export default {
  mixins: [categoriesMixin],
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useDataStore();
    return {
      categories: store.categories,
      selectedCategory: store.selectedCategory,
      store,
    };
  },
  watch: {
    selectedCategory(newValue) {
      this.$emit("updateCategory", newValue);
    },
  },
  created() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
}

.input-error {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 12px;
}
</style>
