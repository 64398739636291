// fetchdata.js
import { reactive } from "vue";
import { preferences } from "@/config.js";

const data = reactive({
  mapID: null,
  logoUrl: preferences.appInfo.logoUrl,
  logoAltText: preferences.appInfo.logoAltText,
  contactEmail: preferences.appInfo.contactEmail,
  termsLink: preferences.externalLinks.termsLink,
  surveyLink: preferences.externalLinks.surveyLink,
  brochureLink: preferences.externalLinks.brochureLink,
  uploadMaxFilesize: process.env.VUE_APP_UPLOAD_MAX_FILESIZE_MB, // use get data if env var are changable 
});

export default {
  methods: {
    // get data from api, check api if there is no value then fall back to data store 
    getData(key) {
      return data[key];
    },
    setData(key, value) {
      console.log("before:", data[key]);
      data[key] = value;
      console.log("after:", data[key]);
      return data[key];
    },
  },
};
// dont create the api in s=this file 