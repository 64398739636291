<template>
  <div class="form-group">
    <label>{{ $t('form.Description') }}</label>
    <textarea
      v-model="description"
      :placeholder="$t('form.descriptionPlaceholder')"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    initialDescription: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      description: this.initialDescription,
    };
  },
  watch: {
    description(newValue) {
      this.$emit("updateDescription", newValue);
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
  height: 100px;
  resize: none;
}
</style>
