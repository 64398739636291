<template>
  <div class="search-bar-wrapper">
    <v-row no-gutters>
      <v-text-field
        v-model="searchQuery"
        :placeholder="$t('search.placeholder')"
        @keypress.enter.prevent="searchAddress"
        outlined
        dense
        solo
        class="search-input"
        color="primary"
          style="height: 50px" 
      />
      <v-btn
        color="primary"
        large
        class="search-button"
        @click="searchAddress"
        icon
        :style="{ height: '50px' }"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { useDataStore } from "@/stores/dataStore";
import mapMixin from "@/mixins/mapMixin";

export default {
  mixins: [mapMixin],
  data() {
    return {
      searchQuery: "",
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  methods: {
    async searchAddress() {
      const store = useDataStore();
      if (!store.map) {
        console.error("Map is not initialized");
        return;
      }

      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${this.searchQuery}`
        );
        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          store.map.setView([lat, lon], 13); // Center the map on the searched location
          this.updateSearchMarker(parseFloat(lat), parseFloat(lon)); // Update the search marker on the map
        } else {
          alert("Address not found");
        }
      } catch (error) {
        console.error("Error searching address:", error);
      }
    },
  },
};
</script>

<style scoped>
.search-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1565c0ef;
  border-radius: 4px;
}

.v-text-field {
  background-color: white;
  border-radius: 2px;
}
.search-button {
  height: 56px;
  border-radius: 0px;
}
</style>
