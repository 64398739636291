<template>
  <div class="form-group">
    <label>{{ $t('form.addFileLabel') }}</label>
    <input
      type="file"
      :accept="getFileType()"
      @change="handleFileChange"
      :class="{ 'input-error': errors.selectedFile }"
    />
    <span v-if="errors.selectedFile" class="error-text">
      {{ $t('form.fileRequired') }}
    </span>
    <small class="form-text text-muted">
      {{ $t('form.maxFileSize') }} {{ uploadMaxFilesize }} MB.
    </small>
  </div>
</template>


<script>
import { useDataStore } from "@/stores/dataStore";
import fetchData from "@/mixins/fetchData.js";


export default {
  mixins: [fetchData],
  props: {
    errors: {
      type: Object,
      required: true,
    },
    fileType: {
      type: String,
      default: "image", 
    },
  },
  data() {
    return {
      uploadMaxFilesize: process.env.VUE_APP_UPLOAD_MAX_FILESIZE_MB,
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  methods: {
    getFileType() {
      if (this.fileType === "audio") {
        return "audio/*";
      } else if (this.fileType === "image") {
        return "image/*";
      } else if (this.fileType === "video") {
        return "video/*";
      } else {
        return "*/*"; // Accept all types if not specified
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const fileType = file ? file.type.split("/")[0] : "";

      if (file && fileType === this.fileType) {
        this.store.setSelectedFile(file);
        this.store.setUploadID(null);
        this.$emit("updateError", { selectedFile: false });
      } else {
        this.$emit("updateError", { selectedFile: true });
        alert(`Please select a valid ${this.fileType} file.`);
        event.target.value = "";
      }
      this.$emit("updateFile", this.store.selectedFile);
    },
  },
};

</script>

<style scoped>
.input-error {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 12px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #d6dbce;
  font-size: 16px;
  font-style: italic;
  cursor: pointer;
}
</style>
