<template>
  <v-toolbar color="secondary" dark>
    <v-btn icon="mdi-arrow-left" @click="$router.push('/')"></v-btn>
    <v-toolbar-title>{{ $t("managePage.title") }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      class="add-item-btn"
      color="white"
      outlined
      :to="{ name: 'MainApp' }"
    >
      {{ $t("managePage.addPage") }}
    </v-btn>
  </v-toolbar>

  <v-container>
    <v-row>
      <v-col v-for="item in items" :key="item.id" cols="12" sm="6" md="4">
        <POICard
          @markCollectedClicked="markCollectedClickedHandler"
          :item="item"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert v-if="items.length === 0" value="true" type="info" dismissible>
          {{ $t("managePage.noItems") }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("managePage.confirmationTitle")
        }}</v-card-title>
        <v-card-text>
          {{ $t("managePage.confirmationText") }}
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="green darken-1" text @click="markCollected"
                >Yes</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn color="red darken-1" text @click="showDialog = false"
                >No</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/db";
import POICard from "@/components/POICard.vue";
import { patchUsersMap } from "@/services";
import { useDataStore } from "@/stores/dataStore";
import VueCookies from "vue-cookies";

export default {
  components: {
    POICard,
  },
  data() {
    return {
      items: [],
      showDialog: false,
      selectedItem: {},
    };
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  async created() {
    await this.fetchItems();
  },
  computed: {
    isBasicAuthenticated() {
      return this.store.isBasicAuthenticated;
    },
  },
  methods: {
    async fetchItems() {
      this.items = await db.pois.toArray();
      console.log("stored", this.items);
    },
    async markCollected() {
      if (!this.isBasicAuthenticated) return;

      const pass_key = VueCookies.get("pass_key");
      const id_field = VueCookies.get("id_field");

      const data = {
        map_json: {
          generic_data: {
            map_level: [],
            poi_level: [
              {
                bool_fields: [
                  {
                    collected: true,
                  },
                ],
                name: this.selectedItem.name,
              },
            ],
          },
        },
        org_domain_name: process.env.VUE_APP_ORG_DOMAIN,
        org_key: process.env.VUE_APP_ORG_KEY,
        pass_key: pass_key,
        id_field: id_field,
      };

      const map_pk = VueCookies.get("map_pk");

      const response = await patchUsersMap(map_pk, data);
      console.log(response);

      // Remove the item from the database by id
      try {
        await db.pois.where("id").equals(this.selectedItem.id).delete();
      } catch (error) {
        console.error(error);
      }
      Object.assign(this.selectedItem, {});
      this.showDialog = false;
      await this.fetchItems();
    },
    markCollectedClickedHandler(item) {
      Object.assign(this.selectedItem, item);
      this.showDialog = true;
    },
  },
};
</script>

<style scoped>
.add-item-btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 20px;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.add-item-btn:hover {
  background-color: #494d4d;
  color: white;
}
</style>
