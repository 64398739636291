<template>
  <div class="viewer-container">
    <div class="header">
      <v-btn
        color="primary"
        class="home-button"
        large
        :to="{ name: 'LandingPage' }"
      >
        Home
      </v-btn>
    </div>

    <!-- Filters Section -->
    <div class="filter-container">
      <div class="filter-row">
        <v-select
          v-model="selectedCategory"
          :items="categoryOptions"
          :label="$t('viewerPage.filterCategory')"
          class="category-dropdown"
          bg-color="white"
        ></v-select>

        <v-select
          v-model="selectedSubCategory"
          :items="subCategoryOptions"
          :label="$t('viewerPage.filterSubcategory')"
          class="subcategory-dropdown"
          bg-color="white"
        ></v-select>
      </div>
      <v-btn color="secondary" class="clear-button" large @click="clearFilters">
        {{ $t("viewerPage.clearFilters") }}
      </v-btn>
    </div>

    <v-card class="map-container" id="map"> </v-card>

    <v-overlay v-if="store.loading" absolute>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <v-alert v-if="store.error" type="error" dismissible>
      {{ store.error }}
    </v-alert>

    <PoiDetail
      v-if="store.selectedPoi"
      :isVisible="store.isModalVisible"
      :poi="store.selectedPoi"
      :tags="store.maps.tags || []"
      @close="closeModal"
    />
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import PoiDetail from "../components/PoiDetail.vue";
import { useDataStore } from "@/stores/dataStore";
import { useRouter, useRoute } from "vue-router";
import api from "@/endpoints/api";
import categoriesMixin from "@/mixins/categoriesMixin";
import { toRaw } from "@vue/reactivity";

export default {
  name: "ViewerApp",
  components: {
    PoiDetail,
  },
  mixins: [categoriesMixin],
  setup() {
    const store = useDataStore();
    const router = useRouter();
    const route = useRoute();
    return { store, router, route };
  },
  data() {
    return {
      map: null,
      markers: [],
      markerClusterGroup: null,
      selectedCategory: null,
      selectedSubCategory: null,
      categoryOptions: [],
      subCategoryOptions: [],
    };
  },
  beforeUnmount() {
    this.store.resetStoreToDefaults();
  },
  async created() {
    const mapId = this.$route.params.id;
    console.log(mapId);
    const orgDomainName = process.env.VUE_APP_ORG_DOMAIN;
    await this.fetchMapData(mapId, orgDomainName);

    this.categoryOptions = this.getCategoryOptions();
    this.subCategoryOptions = this.getSubCategoryOptions();
  },
  watch: {
    selectedCategory(newCategory) {
      if (newCategory) {
        this.selectedSubCategory = null;
      }
      this.filterPOIs();
    },
    selectedSubCategory(newSubCategory) {
      if (newSubCategory) {
        this.selectedCategory = null;
      }
      this.filterPOIs();
    },
    mapID: {
      immediate: true,
      handler(newMapId) {
        if (newMapId) {
          const orgDomainName = process.env.VUE_APP_ORG_DOMAIN;
          this.fetchMapData(newMapId, orgDomainName);
        }
      },
    },
  },
  
  methods: {
    clearFilters() {
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.filterPOIs();
    },
    async fetchMapData(mapId, orgDomainName) {
      this.store.setLoading(true);
      this.store.setError(null);
      try {
        const response = await api.get(`/api/maps/${mapId}/`, {
          params: { org_domain_name: orgDomainName },
        });
        const mapData = response.data.map_json;

        this.store.setPayload({
          ...this.store.payload,
          id_field: response.data.id_field || 8,
          pass_key: response.data.pass_key,
          org_key: response.data.org_key || "abcd",
          org_domain_name: response.data.org_domain_name || orgDomainName,
        });

        if (mapData && mapData.center && mapData.center.coordinates) {
          this.store.setMaps(mapData);
          this.store.pois = mapData.pois || [];

          this.initializeMap(mapData.center.coordinates);
          this.addMarkers(this.store.pois);

          this.store.setLoading(false);
        } else {
          this.store.setError("Center geometry is missing or malformed.");
          this.store.setLoading(false);
        }
      } catch (error) {
        this.store.setError("Failed to fetch items. Please try again later.");
      } finally {
        this.store.setLoading(false);
      }
    },
    initializeMap(center) {
      this.map = L.map("map").setView([center[1], center[0]], 13);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(this.map);

      // Initialize the marker cluster group
      this.markerClusterGroup = L.markerClusterGroup();
      this.map.addLayer(this.markerClusterGroup);
    },
    addMarkers(pois) {
      pois.forEach((poi) => {
        const latLng = this.getLatLng(poi.geometry);
        if (latLng) {
          const marker = L.marker(latLng);
          marker.on("click", () => this.showModal(poi));
          this.markerClusterGroup.addLayer(marker);
          this.markers.push(marker);
        }
      });
    },
    clearMarkers() {
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = [];
    },
    clearClusteredMarkers() {
      if (this.markerClusterGroup) {
        this.markerClusterGroup.clearLayers();
      }
    },
    filterPOIs() {
      console.log("Selected Category:", this.selectedCategory);
      console.log("Selected Subcategory:", this.selectedSubCategory);

      const filteredPOIs = this.store.pois.filter((poi) => {
        const poiCategories = this.getCategoryNames(
          poi.properties.sub_categories
        );
        const poiSubCategories = this.getSubCategoryNames(
          poi.properties.sub_categories
        );

        const matchesCategory =
          !this.selectedCategory ||
          poiCategories.includes(this.selectedCategory);
        const matchesSubCategory =
          !this.selectedSubCategory ||
          poiSubCategories.includes(this.selectedSubCategory);

        return matchesCategory && matchesSubCategory;
      });

      console.log("Filtered POIs:", filteredPOIs);
      this.clearMarkers();
      this.clearClusteredMarkers();
      this.addMarkers(filteredPOIs);
    },
    getCategoryNames(subCategoryIds) {
      const rawCategoryMap = toRaw(this.store.categoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);
      const categories = [];

      rawSubCategoryIds.forEach((id) => {
        const categoryName = rawCategoryMap[id];
        if (!categories.includes(categoryName)) {
          categories.push(categoryName);
        }
      });

      return categories;
    },
    getSubCategoryNames(subCategoryIds) {
      const rawSubCategoryMap = toRaw(this.store.subCategoryMap);
      const rawSubCategoryIds = toRaw(subCategoryIds);

      return rawSubCategoryIds.map(
        (id) => rawSubCategoryMap[id] || `Unknown Subcategory ID: ${id}`
      );
    },
    getCategoryOptions() {
      const categories = new Set();
      this.store.pois.forEach((poi) => {
        const categoryNames = this.getCategoryNames(
          poi.properties.sub_categories
        );
        categoryNames.forEach((category) => categories.add(category));
      });
      return Array.from(categories);
    },
    getSubCategoryOptions() {
      const subCategories = new Set();
      this.store.pois.forEach((poi) => {
        const subCategoryNames = this.getSubCategoryNames(
          poi.properties.sub_categories
        );
        subCategoryNames.forEach((subCategory) =>
          subCategories.add(subCategory)
        );
      });
      return Array.from(subCategories);
    },
    getLatLng(geometry) {
      if (
        geometry &&
        geometry.coordinates &&
        geometry.coordinates.length === 2
      ) {
        return [geometry.coordinates[1], geometry.coordinates[0]];
      }
      return null;
    },
    showModal(poi) {
      this.store.setSelectedPoi(poi);
      this.store.setModalVisible(true);
    },
    closeModal() {
      this.store.setModalVisible(false);
      this.store.setSelectedPoi(null);
    },
  },
};
</script>

<style scoped>
.header {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.filter-container {
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.v-input {
  grid-template-rows: auto 5px;
}

.v-btn {
  width: 20vw;
  color: white;
}

.error {
  color: red;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
@media (max-width: 600px) {
  .filter-container {
    position: absolute;
    bottom: 0px;
    top: 600px;
    right: 10px;
    left: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }

  .filter-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .category-dropdown,
  .subcategory-dropdown {
    width: 45vw;
    gap: 1vw;
  }

  .clear-button {
    width: 95vw;
    text-align: center;
  }
}
</style>
