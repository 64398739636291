<template>
  <v-card>
    <v-img
      :lazy-src="placeholderImage"
      :src="imageURL"
      height="200px"
    ></v-img>
    <!-- TODO: Consider adding title and caption -->
    <v-card-actions>
      <v-btn
        class="bg-secondary"
        @click="emitMarkCollectedClicked"
        :disabled="item.collected"
      >
        {{ $t('card.markAs') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import placeholderImage from '@/assets/spinningMapItemPlaceholder.svg'
import { s3Download } from "@/utils/fileDownload";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      placeholderImage: placeholderImage,
      imageURL: "",
      showDialog: false
    };
  },
  mounted() {
    s3Download(this.item.file_key).then((imageURL) => {
      this.imageURL = imageURL
    })
  },
  methods: {
    emitMarkCollectedClicked() {
      this.$emit('markCollectedClicked', this.item);
    }
  }
};
</script>