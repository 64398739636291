<!-- Currently not in use -->

<template>
  <v-container :style="backgroundStyle" class="fill-height" fluid>
    <v-row class="d-flex justify-center align-center text-center">
      <v-col cols="12" md="8">
        <v-card class="pa-6" elevation="2">
          <v-card-title class="headline mb-2">
            {{ $t("splashPage.title") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("splashPage.subtitle") }}
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            {{ $t("splashPage.text") }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <a
              :href="preferences.splashPage.supportUrl"
              target="_blank"
              style="text-decoration: underline; color: blue"
            >
              {{ $t("splashPage.supportText") }}
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { preferences } from "@/config.js";

export default {
  name: "SplashPage",
  data() {
    return {
      preferences,
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.preferences.splashPage.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh !important;
}
.pa-6 {
  padding: 24px !important;
  max-width: 90vw;
}
.v-card-subtitle {
  white-space: normal;
}
.v-card-title {
  white-space: normal;
}
</style>
