<template>
  <v-app>
    <v-container class="fill-height d-flex align-center justify-center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" sm="12" class="text-center">
          <v-img
            :src="getData('logoUrl')"
            :alt="getData('logoAltText')"
            :title="getData('logoAltText')"
            contain
            max-height="400"
            class="mb-4"
          ></v-img>

          <v-card class="pa-10" color="secondary" dark>
            <v-card-title class="display-1">
              {{ $t('landingPage.welcomeMessage') }}
            </v-card-title>
            <v-card-text class="subtitle-1 mb-6">
              {{ $t('landingPage.description') }}
            </v-card-text>

            <v-btn color="primary" class="mx-2" large :to="{ name: 'MainApp' }">
              {{ $t('landingPage.postButton') }}
            </v-btn>
            <v-btn
              v-if="mapId"
              color="success"
              class="mx-2"
              large
              outlined
              :to="{ name: 'ViewerApp', params: { id: mapId } }"
            >
              {{ $t('landingPage.viewButton') }}
            </v-btn>

            <v-card-actions class="justify-center">
              <v-btn :to="{ name: 'UserItems' }">{{ $t('landingPage.manageButton') }}</v-btn>
              <v-btn :href="getMailto()" class="mx-2">
                {{ $t('landingPage.contactUs') }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="mt-4 pa-3" color="grey lighten-4">
            <!--  <v-card-text class="text-center">
              Thanks to <strong>Divert NS</strong> for funding and to our other
              partners for their support.
            </v-card-text>
          -->
            <v-card-text class="text-center">
              <div>
                {{ $t('landingPage.termsMessage') }}
                <a :href="getData('termsLink')" class="link">
                  {{ $t('landingPage.termsAndConditions') }}
                </a>
              </div>
            </v-card-text>
          </v-card>

          <!-- Next Project Section -->
          <!--  <v-card class="mt-6" flat>
            <v-card-text class="text-center">
              <div>
                <strong>Preview Our Next Positive Change Initiative </strong>
                <a href="http://ns.flagthis.ca/" target="_blank" class="link"
                  >Risk Awareness</a 
                >
              </div>
            </v-card-text>
          </v-card> -->

          <!-- Survey Section -->
          <v-card class="mt-6" flat>
            <v-card-text class="text-center">
              <strong>{{ $t('landingPage.surveyPrompt') }}</strong>
              <a :href="getData('surveyLink')" target="_blank" class="link">
                {{ $t('landingPage.completeSurvey') }}
              </a>
            </v-card-text>
          </v-card>


          <v-card class="mt-6" flat>
            <v-card-text>
              <a :href="getData('brochureLink')" target="_blank" class="link">
                {{ $t('landingPage.aboutUs') }}
              </a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { fetchPublicOrgMaps } from "@/services.js";
import { useDataStore } from "@/stores/dataStore";
import fetchData from "@/mixins/fetchData.js";

export default {
  name: "LandingPage",
  mixins: [fetchData],

  methods: {
    getMailto() {
      return `mailto:${this.getData('contactEmail')}?subject=${this.$t('landingPage.emailSubject')}`;
    },
   
  },
  computed: {
    mapId: function () {
      console.log('mapid', this.getData('mapID'))
      return this.getData('mapID');
    }
  },
  setup() {
    const store = useDataStore();
    return { store };
  },
  async mounted() {
    const orgMapdata = await fetchPublicOrgMaps();
    const previewMap = orgMapdata.data.find(
      (map) => map.map_name === "public-map"
    );
    const mapID = previewMap ? previewMap.map_obj : null;
    this.setData("mapID", mapID); // Update mapID in fetchdata.js
  },
};
</script>

<style scoped>
.fill-height {
  max-height: 100vh;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: darkblue;
}
.v-card-text {
  padding: 0.3rem;
}
.v-card-title {
  white-space: normal;
}

@media (max-width: 600px) {
  .v-img {
    max-height: 200px !important;
  }
  .v-col-12 {
    flex: 0 0 100%;
    max-width: 100vw;
  }
}
</style>
