import { createRouter, createWebHistory } from "vue-router";
import MainApp from "@/views/MainApp.vue";
import MapsList from "@/views/MapsList.vue";
import ViewerApp from "@/views/ViewerApp.vue";
import LandingPage from "@/views/LandingPage.vue";
import PoiDetail from "@/components/PoiDetail.vue";
import UserItems from "@/views/UserItems.vue";
import SplashPage from "@/views/SplashPage.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/give",
    name: "MainApp",
    component: MainApp,
  },
  {
    path: "/items",
    name: "UserItems",
    component: UserItems,
  },
  {
    path: "/maps",
    name: "MapsList",
    component: MapsList,
  },
  {
    path: "/viewer/:id",
    name: "ViewerApp",
    component: ViewerApp,
    props: true,
  },
  {
    path: "/detail/:id",
    name: "PoiDetail",
    component: PoiDetail,
    props: true,
  },
  {
    path: "/splash",
    name: "SplashPage",
    component: SplashPage,
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
