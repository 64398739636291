// src/dexieDB.js
import Dexie from 'dexie';

export const db = new Dexie('valleygiveandtake');

const poisIndexes = [
  '++id', 'poi_id', 'file_key', 'name', 
]

db.version(1).stores({
  pois: poisIndexes.join(', '),
  submissions: "++id, payload, map_pk" 
});