export const preferences = {
  appInfo: {
    logoUrl: "/logo.png",
    logoAltText: " Give & Take Stuff Logo",
    contactEmail: "info@strollopia.com",
  },
  externalLinks: {
    termsLink: "/terms",
    surveyLink: "https://forms.gle/xzf77sHx7mAzw4zt9",
    brochureLink: "/ValleyGiveandTakebrochure.pdf",
  },
  splashPage: {
    supportUrl: "https://gofund.me/76143d29",
    backgroundImage: "/android-chrome-512x512.png"
  },
};
