import { db } from "@/db";
import api from "@/endpoints/api";
import VueCookies from "vue-cookies";

export const saveOfflineSubmission = async (payload) => {
  try {
    const map_pk = VueCookies.get("map_pk") || null;
    await db.submissions.add({ id: Date.now(), payload, map_pk });
    console.log("Saved offline submission:", payload);
  } catch (error) {
    console.error("Error saving offline submission:", error);
  }
};

export const syncOfflineSubmissions = async () => {
  const submissions = await db.submissions.toArray();

  for (const submission of submissions) {
    try {
      const { payload, map_pk } = submission;
      let response;

      if (map_pk) {
        response = await api.patch(`/api/anon-map-update/${map_pk}/`, payload);
        console.log("Map updated successfully:", response.data);
      } else {
        response = await api.post("/api/anon-map-create/", payload);
        console.log("New map created successfully:", response.data);

        const new_map_pk = response?.data?.map_pk;
        if (new_map_pk) {
          VueCookies.set("map_pk", new_map_pk, "1y");
          console.log("map_pk set: ", new_map_pk);
        }
      }

      await db.submissions.delete(submission.id);
      console.log("Submission synced and removed from local DB");
    } catch (error) {
      console.error("Error syncing offline submission:", error);
    }
  }
};
