import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import { createPinia } from "pinia";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import { aliases, mdi } from "vuetify/iconsets/mdi";

import { createI18n } from "vue-i18n";

import en from "./locales/en.json";

const app = createApp(App);
const pinia = createPinia();

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
  },
});

const vuetify = createVuetify({
  components,
  directives,
  aliases,
  sets: {
    mdi,
  },
});

app.use(router);
app.use(pinia);
app.use(vuetify);
app.use(i18n);

app.mount("#app");
