import api from "@/endpoints/api";

export const fetchPublicOrgMaps = async () => {
  const params = {
    owner__org_domain_name: process.env.VUE_APP_ORG_DOMAIN,
    map_name: "public-map",
  };
  const response = await api.get(
    "/api/public-org-map/", {
      params,
    }
  );
  return response;
}

// Patch Users Map
export const patchUsersMap = async (mapID, data) => {
  const response = await api.patch(
    `/api/anon-map-update/${mapID}/?org_domain_name=${process.env.VUE_APP_ORG_DOMAIN}`,
    data
  ); // take data and transformate taking the org domain name out 
  
  return response;
}

//create a constant a method to use in fetch data to call the endpoint to get the data 
// another async method to return for now a moched obj to give me the value that is changable 
//the response to get from the server should have at min a key with value  that i requested and its value 
//so this way testing is more accurate if the valuse does not exist i should fall back, if there is no key then its a 404
//states a key with value, a key without value, a key that does not exist 
//once i get the value it would have to be stored, istead of calling the fetch everytime then cash it (preformeance issues) 
//talk about a watch in the backend 
//sreach using service worker to use the app offline 